import styled, { css } from 'styled-components';
import { breakpointUp, spacer, specific, zIndex } from 'theme';
import { BREAKPOINTS } from '../../constants/breakpoints';

export const StyledNotificationsWrapper = styled.div`
  display: none;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      position: fixed;
      transform: translateX(-50%);
      height: 0;
      left: 50%;
      top: calc(${specific('topbar', 'height')} + ${specific('mainbar', 'height')});
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      z-index: ${zIndex('modal-overwrite')};

      > * {
        // Margin-bottom on children so this margin can be removed on transition exit so the notifications don't jump upwards.
        margin-bottom: ${spacer(50)};
      }

      .notification-enter {
        opacity: 0;
        max-height: 500px;
        transform: scale(0.9);
      }

      .notification-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition:
          opacity 250ms,
          transform 250ms;
      }

      .notification-exit {
        opacity: 1;
        max-height: 500px;
      }

      // Removing padding, max-height and margin-bottom to make the cards move up smoothly.
      // Couldn't really find any example of this online so improvements are always welcome.
      .notification-exit-active {
        opacity: 0;
        transform: scale(0.9);
        max-height: 0;
        padding: 0px;
        margin-bottom: 0px;
        transition:
          opacity 250ms,
          transform 250ms,
          max-height 400ms,
          padding 400ms,
          margin-bottom 400ms;
      }
    `,
  )};
`;
