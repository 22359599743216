import useRouter from 'hooks/useRouter';
import { pathnames } from 'i18n/pathnames';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { NotificationAction, NotificationType } from 'types/Notification';
import { getLocalizedRoute } from 'utils/localizedRouteUtil';
import { ICON_NAMES } from '../../constants/icon';
import { NOTIFICATION_CLOSE_DELAY, NOTIFICATION_ICONS } from '../../constants/notifications';
import Button from '../Button/Button';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import Typography from '../Typography/Typography';
import { NotificationContent, NotificationIconWrapper, NotificationWrapper } from './Notification.styled';

type NotificationProps = {
  actions?: NotificationAction[];
  autoClose?: boolean;
  closeNotification: (uuid: string) => void;
  descriptionId?: string;
  message?: string;
  timeoutDuration?: number;
  titleId?: string;
  type?: NotificationType;
  uuid: string;
};

const Notification = ({
  actions,
  autoClose = true,
  closeNotification,
  descriptionId,
  message,
  timeoutDuration = NOTIFICATION_CLOSE_DELAY,
  titleId,
  type = 'info',
  uuid,
}: NotificationProps) => {
  const { formatMessage } = useIntl();
  const { locale, pathname } = useRouter();

  const handleClose = () => closeNotification(uuid);

  useEffect(() => {
    const closingTimer =
      autoClose &&
      setTimeout(() => {
        handleClose();
        !!closingTimer && clearTimeout(closingTimer);
      }, timeoutDuration);

    return () => {
      if (autoClose && !!closingTimer) {
        clearTimeout(closingTimer);
      }
    };
  }, []);

  const icon = NOTIFICATION_ICONS?.[type]?.name;

  return (
    <NotificationWrapper className="notification-wrapper">
      <NotificationIconWrapper colorScheme={NOTIFICATION_ICONS[type]?.color ?? 'secondary'}>
        {!!icon && <Icon name={icon} size={100} />}
      </NotificationIconWrapper>
      <NotificationContent>
        {!!titleId && (
          <Typography marginBottom={0} type="h4">
            {formatMessage({ id: titleId })}
          </Typography>
        )}
        {(!!message || !!descriptionId) && <Typography>{message || formatMessage({ id: descriptionId })}</Typography>}
        {actions && (
          <Flex flexWrap="wrap" gap={50} justifyContent="space-between">
            {actions.map((action, index) => {
              const { href, labelId } = action;
              return (
                <Link
                  key={`${labelId}-notification`}
                  passHref
                  url={getLocalizedRoute(href, locale, {
                    query: {
                      ...(pathname === pathnames.SEARCH && { fromSearch: true }),
                    },
                  })}
                >
                  <Button
                    color={index > 0 ? 'secondary' : 'primary'}
                    fullwidth={actions.length > 1}
                    onClick={handleClose}
                    outlined={index > 0}
                  >
                    {formatMessage({ id: labelId })}
                  </Button>
                </Link>
              );
            })}
          </Flex>
        )}
      </NotificationContent>
      <div className="notification-close-icon" onClick={handleClose} role="button" tabIndex={0}>
        <Icon name={ICON_NAMES.TIMES} size={75} />
      </div>
    </NotificationWrapper>
  );
};

export default Notification;
