import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useNotificationActions, useNotifications } from 'store/notification';
import Notification from '../Notification/Notification';
import { StyledNotificationsWrapper } from './NotificationWrapper.styled';

const NotificationsWrapper = () => {
  const notifications = useNotifications();
  const { removeNotification } = useNotificationActions();

  const closeNotification = (uuid: string) => {
    removeNotification(uuid);
  };

  return (
    <StyledNotificationsWrapper className="container">
      <TransitionGroup>
        {notifications?.map(({ actions, descriptionId, message, titleId, type, uuid }) => (
          <CSSTransition key={uuid} classNames="notification" timeout={250}>
            <Notification
              actions={actions}
              closeNotification={closeNotification}
              descriptionId={descriptionId}
              message={message}
              titleId={titleId}
              type={type}
              uuid={uuid}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </StyledNotificationsWrapper>
  );
};

export default NotificationsWrapper;
