import { NOTIFICATION_MAPPER } from 'constants/notifications';
import styled from 'styled-components';
import { color, spacer, variable } from 'theme';

export const NotificationWrapper = styled.div`
  background-color: ${color('white')};
  padding: ${spacer(100)};
  border-radius: ${variable('border-radius')};
  box-shadow: ${variable('box-shadow')};
  display: flex;
  gap: ${spacer(100)};
  width: 400px;

  .typography {
    word-break: break-word;
  }

  .notification-close-icon {
    height: fit-content;
    cursor: pointer;
    padding: 0px ${spacer(50)} ${spacer(50)};
  }
`;

type NotificationIconWrapperProps = {
  colorScheme: keyof typeof NOTIFICATION_MAPPER;
};

export const NotificationIconWrapper = styled.div<NotificationIconWrapperProps>`
  padding: ${spacer(50)};
  height: 32px;
  width: 32px;
  border-radius: 100%;
  fill: ${({ colorScheme }) =>
    !!NOTIFICATION_MAPPER?.[colorScheme]?.color && NOTIFICATION_MAPPER?.[colorScheme]?.color};
  color: ${({ colorScheme }) => !!NOTIFICATION_MAPPER[colorScheme]?.color && NOTIFICATION_MAPPER[colorScheme]?.color};
  background-color: ${({ colorScheme }) => NOTIFICATION_MAPPER?.[colorScheme]?.background};
  display: flex;
  align-items: center;
`;

export const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${spacer(75)};
  overflow-y: auto;
`;
